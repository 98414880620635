import React from 'react';

import Map from './Map.imports-loadable';
import StaticMap from './StaticMap';
import MapSkeleton from './MapSkeleton';
import { classNames } from '../../utils/withStyles';
import { useLazyScrollObserver } from '../../utils/useLazyScrollObserver';
import { visualRegressionsMode } from '../../utils/visualRegressionsMode';
import { isSSR } from '../../utils/dom';

const ScrolledMap = (props) => {
  const { scrolledTo, scrollObserverRef } = useLazyScrollObserver();

  const fallback = (
    // A copy of similar div in app/javascript/shared/Map/Map.jsx
    // It's necessary to avoid CSS conflicts.
    // Some users has written CSS selectors changing the skeleton outlook.
    <div
      id="mapbox"
      className={classNames('pm-map-wrap', props.className)}
      role="presentation"
      ref={scrollObserverRef}
    >
      <MapSkeleton hasDraggableMarker={props.hasDraggableMarker} pinsCount={props.markers.length} />
    </div>
  );

  if (visualRegressionsMode) {
    return fallback;
  }

  if (props.markers.length === 1) {
    return <StaticMap {...props} />;
  }

  if (!scrolledTo || isSSR) return fallback;
  return <Map fallback={fallback} {...props} />;
};

export default ScrolledMap;
