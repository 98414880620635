import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@popmenu/common-ui';
import { Facebook, Instagram, Linkedin, Twitter, Youtube, PinterestIcon, GoogleIcon } from '@popmenu/web-icons';
import { SnapchatIcon, TiktokIcon } from './Icons';

const ICON_MAP = {
  facebook: Facebook,
  google: GoogleIcon,
  instagram: Instagram,
  linkedin: Linkedin,
  pinterest: PinterestIcon,
  snapchat: SnapchatIcon,
  tiktok: TiktokIcon,
  twitter: Twitter,
  youtube: Youtube,
};

const SocialIcon = ({ icon, useDefaultColor, ...props }) => {
  const Component = ICON_MAP[icon];
  if (!Component) {
    return null;
  }

  const style = props.style || {};
  if (useDefaultColor) {
    switch (icon) {
      case 'facebook':
        style.color = '#3C5A99';
        break;
      case 'twitter':
        style.color = '#1DA1F2';
        break;
    }
  }

  return (
    <Icon
      aria-label={props['aria-label']}
      fontSize="inherit"
      {...props}
      icon={Component}
      style={style}
    />
  );
};

SocialIcon.defaultProps = {
  style: null,
  useDefaultColor: false,
};

SocialIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  style: PropTypes.object,
  useDefaultColor: PropTypes.bool,
};

export default SocialIcon;
