import React from 'react';

const SvgIcon = ({
  cursor,
  fill,
  children,
  viewBoxWidth,
  viewBoxHeight,
  stroke,
  strokeWidth,
  ...otherProps
}) => (
  <svg
    viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
    xmlns="http://www.w3.org/2000/svg"
    cursor={cursor}
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    {...otherProps}
  >
    {children}
  </svg>
);

export default SvgIcon;
