export default theme => ({
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    display: 'flex',
    height: '100%',
    position: 'relative',
  },
  locationInfoContainer: {
    marginTop: theme.spacing(5),
    padding: '20px 15px',
    position: 'relative',
    transform: 'none',
  },
  locationInfoTriangle: {
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderTopColor: '#ebebeb',
    borderTopWidth: '24px',
    borderWidth: '14px',
    bottom: '-32px',
    height: '0px',
    left: '0',
    margin: 'auto',
    position: 'absolute',
    right: '0',
    width: '0px',
  },
  mapPinSkeleton: {
    '&:before': {
      borderColor: 'transparent',
      borderStyle: 'solid',
      borderTopColor: 'inherit',
      borderTopWidth: '24px',
      borderWidth: '14px',
      bottom: '-39px',
      height: '0px',
      left: '-9px',
      position: 'absolute',
      width: '0px',
    },
    backgroundColor: 'transparent',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '10px',
    color: '#ebebeb',
    height: '30px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(5),
    transform: 'translate(0, -30px)',
    width: '30px',
  },
  mapRandomPin: {
    position: 'absolute',
  },
  mapSearchSkeleton: {
    height: '50px',
    position: 'absolute',
    right: '12px',
    top: '12px',
  },
  mapTextSkeleton: {
    alignItems: 'center',
    bottom: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    left: '12px',
    position: 'absolute',
    width: '95px',
  },
  mapZoomSkeleton: {
    bottom: '12px',
    height: '60px',
    position: 'absolute',
    right: '12px',
    transform: 'none',
    width: '24px',
  },
});
