import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@popmenu/common-ui';
import { Navigation } from '@popmenu/web-icons';
import { FormattedMessage } from 'react-intl';

import { toLocationMapUrl } from '../../utils/utils';

const DirectionsButton = ({ location }) => (
  <Button
    aria-label={`Get Directions to ${location.name} on Google Maps (opens in a new window)`}
    className="pm-location-get-directions"
    color="inherit"
    href={toLocationMapUrl(location)}
    rel="noopener"
    size="small"
    startIcon={<Icon icon={Navigation} />}
    style={{ margin: '8px 0' }}
    target="_blank"
    variant="outlined"
  >
    <FormattedMessage
      id="consumer.directions_button.get_directions"
      defaultMessage="Get Directions"
    />
  </Button>
);

DirectionsButton.propTypes = {
  location: PropTypes.shape({
    googlePlaceId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default DirectionsButton;
