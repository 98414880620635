import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';

import { classNames, withStyles } from '../../../utils/withStyles';
import mapSkeletonStyles from './styles';

import Grid from '../../Grid';

const MapSkeleton = props => (
  <div className={props.classes.container}>
    <Skeleton className={props.classes.mapSearchSkeleton} width="25%" />
    <div className={props.classes.mapTextSkeleton}>
      <Skeleton variant="circle" width="19px" />
      <Skeleton width="70px" />
    </div>
    <Skeleton className={props.classes.mapZoomSkeleton} />
    <Grid container justify="center" spacing={2}>
      {!props.hasDraggableMarker && (
        <Grid item xs={8} sm={4}>
          <Skeleton className={props.classes.locationInfoContainer} width="100%">
            <Skeleton width="60%" />
            <Skeleton width="100%" />
            <Skeleton width="70%" />
            <Skeleton height="50px" width="50%" />
            <Skeleton width="30%" />
            <div className={props.classes.locationInfoTriangle} />
          </Skeleton>
        </Grid>
      )}
      <Grid item xs={12}>
        <Skeleton className={props.classes.mapPinSkeleton} />
        {props.pinsCount > 1 && (
          <Skeleton
            className={classNames(props.classes.mapPinSkeleton, props.classes.mapRandomPin)}
            style={{ left: '20%', top: '10%' }}
          />
        )}
        {props.pinsCount > 2 && (
          <Skeleton
            className={classNames(props.classes.mapPinSkeleton, props.classes.mapRandomPin)}
            style={{ bottom: '20%', left: '10%' }}
          />
        )}
        {props.pinsCount > 3 && (
          <Skeleton
            className={classNames(props.classes.mapPinSkeleton, props.classes.mapRandomPin)}
            style={{ right: '20%', top: '20%' }}
          />
        )}
        {props.pinsCount > 4 && (
          <Skeleton
            className={classNames(props.classes.mapPinSkeleton, props.classes.mapRandomPin)}
            style={{ bottom: '15%', right: '10%' }}
          />
        )}
      </Grid>
    </Grid>
  </div>
);

MapSkeleton.defaultProps = {
  hasDraggableMarker: false,
  pinsCount: 1,
};

MapSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
  hasDraggableMarker: PropTypes.bool,
  pinsCount: PropTypes.number,
};

export default withStyles(mapSkeletonStyles)(MapSkeleton);
