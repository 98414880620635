import { useEffect, useState } from 'react';

export const useUserCoordinates = () => {
  const [userCoordinates, setUserCoordinates] = useState({ lat: null, lng: null });

  const getUserCoordinates = () => {
    const userCoordLat = sessionStorage.getItem('userCachedCoordLat');
    const userCoordLng = sessionStorage.getItem('userCachedCoordLng');
    if (userCoordLat && userCoordLng) {
      setUserCoordinates({
        lat: userCoordLat,
        lng: userCoordLng,
      });
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        sessionStorage.setItem('userCachedCoordLat', position.coords.latitude);
        sessionStorage.setItem('userCachedCoordLng', position.coords.longitude);
        setUserCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  useEffect(() => {
    getUserCoordinates();
  }, []);

  return [userCoordinates, setUserCoordinates];
};
