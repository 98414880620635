import React from 'react';

import SvgIcon from './SvgIcon';

const TiktokIcon = ({
  cursor = 'pointer',
  fill = 'currentColor',
  viewBoxWidth = 24,
  viewBoxHeight = 24,
  width = '1em',
  height = '1em',
  stroke = 'currentColor',
  strokeWidth = 0,
  ...otherProps
}) => (
  <SvgIcon {...{
    cursor,
    fill,
    height,
    stroke,
    strokeWidth,
    viewBoxHeight,
    viewBoxWidth,
    width,
    ...otherProps,
  }}
  >
    <path d="M12.5 0h4c0 1.5.6 3 1.7 4.2A7 7 0 0022.4 6v4a10.7 10.7 0 01-5.8-2v8.8c0 1.4-.6 2.8-1.4 4A7.5 7.5 0 019.3 24c-1.4 0-2.8-.3-4-1a7.5 7.5 0 01-1.1-12.2c1.6-1.4 4-2.1 6.1-1.7v4.4a3.5 3.5 0 00-4.4 2.1c-.2.5-.2 1.1-.1 1.6.2 1.7 1.8 3 3.5 3 1 0 2.1-.7 2.7-1.7.2-.3.4-.7.4-1l.1-5.4V0z" />
  </SvgIcon>
);

export default TiktokIcon;
