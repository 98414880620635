import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@popmenu/common-ui';

import { useIntl } from 'react-intl';
import { toLocationMapUrl } from '../../utils/utils';
import { withPopmenuConfig } from '../../utils/withPopmenuConfig';
import { popmenuConfigShape } from '../../utils/popmenuConfigShape';
import { classNames } from '../../utils/withStyles';

import CustomLink from '../../consumer/shared/CustomLink';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: spacing(1),
    bottom: spacing(1),
    fontSize: 12,
    padding: spacing(1),
    position: 'absolute',
    right: spacing(1),
  },
}));
const useMessages = () => {
  const intl = useIntl();
  return {
    copyright: intl.formatMessage({ defaultMessage: '©', id: 'mapbox-attribution-copyright' }),
    improve: intl.formatMessage({ defaultMessage: 'Improve this map', description: 'prompt', id: 'mapbox-attribution-prompt' }),
    mapbox: intl.formatMessage({ defaultMessage: 'Mapbox', description: 'company name', id: 'mapbox-attribution-mapbox' }),
    openStreetMap: intl.formatMessage({ defaultMessage: 'OpenStreetMap', description: 'company name', id: 'mapbox-attribution-open_street_map' }),
  };
};
const MapBoxAttribution = () => {
  const classes = useStyles();
  const messages = useMessages();
  return (
    <div className={classes.root}>
      {messages.copyright}
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <a href="https://www.mapbox.com/about/maps/" role="link">{messages.mapbox} </a>
      {messages.copyright }
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <a href="http://www.openstreetmap.org/copyright" role="link">{messages.openStreetMap} </a>
      <strong>
        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
        <a href="https://www.mapbox.com/map-feedback/" role="link" target="_blank">{messages.improve}</a>
      </strong>
    </div>
  );
};

class StaticMap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: 400,
      width: 1280,
    };
    this.onResize = this.onResize.bind(this);
    this.mapWrapRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('resize', this.onResize);
      this.onResize();
    }, 500);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  // Update map width/height and reset bounds
  onResize() {
    if (this.mapWrapRef.current) {
      this.setState({
        height: this.mapWrapRef.current.offsetHeight,
        width: this.mapWrapRef.current.offsetWidth,
      });
    }
  }

  render() {
    // Static map for single location restaurants
    const marker = this.props.markers[0];

    const mapTheme = this.props.mapTheme ? this.props.mapTheme : 'streets';
    const width = this.state.width > 1280 ? 1280 : this.state.width;
    const { height } = this.state;
    let mapUrl = '';

    if (marker.useCloudflareMapboxUrl && this.props.restaurant?.slug) {
      mapUrl = `${this.props.popmenuConfig.cloudflareUrl}/${this.props.restaurant.slug}/mapbox/${marker.id}-${mapTheme}-${this.props.defaultZoomLevel}.png`;
    } else {
      const coordinates = `${marker.lng},${marker.lat}`;
      mapUrl = `https://api.mapbox.com/styles/v1/mapbox/${mapTheme}-v9/static/pin-l-marker+d00(${coordinates})/${coordinates},${this.props.defaultZoomLevel}/${width}x${height}@2x?attribution=false&access_token=${this.props.popmenuConfig.mapboxPublicKey}`;
    }

    return (
      <div style={{ position: 'relative' }}>
        <div
          className={classNames('pm-map-wrap', this.props.className)}
          id="mapbox"
          ref={this.mapWrapRef}
          role="presentation"
        >
          <CustomLink
            aria-label="Directions on Google Maps"
            url={toLocationMapUrl(marker)}
          >
            <img
              alt="Map of location"
              className="pm-map-img"
              src={mapUrl}
            />
          </CustomLink>
        </div>
        <MapBoxAttribution />
      </div>
    );
  }
}

StaticMap.defaultProps = {
  className: null,
  defaultZoomLevel: 15,
  mapTheme: 'streets',
  restaurant: null,
};

StaticMap.propTypes = {
  className: PropTypes.string,
  defaultZoomLevel: PropTypes.number,
  mapTheme: PropTypes.oneOf(['dark', 'streets']),
  markers: PropTypes.arrayOf(PropTypes.shape({
    city: PropTypes.string,
    googlePlaceId: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    phone: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    streetAddress: PropTypes.string,
    useCloudflareMapboxUrl: PropTypes.bool,
  })).isRequired,
  popmenuConfig: popmenuConfigShape.isRequired,
  restaurant: PropTypes.shape({
    slug: PropTypes.string,
  }),
};

export default withPopmenuConfig(StaticMap);
